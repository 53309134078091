import React from "react"

import * as styles from "./loading.module.scss"

export default function Loading() {
  return (
    <div className={styles.root}>
      <div className={styles.anime} />
      <h2>Пожалуйста, подождите</h2>
      <p className="text-center" style={{ margin: 30 }}>
        Нам нужно немного времени, чтобы проверить данные
      </p>
    </div>
  )
}
