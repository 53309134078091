import React from "react"

import Layout from "../components/Layouts/secondaryPage"
import Loading from "../components/Loading"

export default function LoadingPage() {
  return (
    <Layout title="Загрузка...">
      <Loading />
    </Layout>
  )
}
